import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import SocialLinks from "../components/SocialLinks";
import Frame from "../components/Frame";

const HeaderStyles = styled.div`
  position: relative;
  z-index: 1;
  min-height: 80vh;
  /*padding: 10%;*/
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  div {
    &.wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      /*max-height: 60vh;*/
      flex-direction: column; /*added*/
      .btn {
        border-radius: 34px;
        background-color: var(--black);
        width: 240px;
        height: 51px;
        margin: 1rem;
        &:hover {
          background-color: var(--red);
        }
      }

      a {
        color: #fff;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .link-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: column;
    padding: 2rem 0;
    a {
      color: var(--white);
      text-decoration: none;
      margin: 0.5rem 0;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  @media (max-width: 1100px) {
    margin-bottom: 5%;
    flex-direction: row;
    width: auto;
    padding: 2rem;

    div {
      &.wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        height: 100%;
        max-height: 120vh;
        flex-direction: column; /*added*/
        padding: 1rem 0;
        .btn {
          width: 225px;
          height: 51px;
        }
        .wrap-im {
          /*height: 360px;*/
          display: flex;
          padding: 6.25rem;
        }
      }
    }

    h1 {
      &.slogan {
        font-size: 3rem;
      }
    }

    h3 {
      &.slogan-desc {
        font-size: 2rem;
        margin-top: 1rem;
        padding: 0 2%;
      }
    }
  }
`;

export default function HomePage({ data }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          instagram
          instagramAlt
          instagramLAC
          whatsapp
        }
      }
    }
  `);

  const { instagram, instagramAlt, instagramLAC, whatsapp } = site.siteMetadata;
  return (
    <div className="center">
      <HeaderStyles className="major">
        <div className="wrap">
          <div className="wrap-im">
            <StaticImage
              src="../assets/images/logo.png"
              className=""
              placeholder="none"
              style={{ maxWidth: "300px" }}
              width={300}
              alt="logo"
            />
          </div>
          <div className="btn">
            <a href="https://wa.me/c/573216168239">
              <StaticImage
                src="../assets/images/TIENDA.png"
                className=""
                placeholder="none"
                style={{ maxWidth: "240px" }}
                width={240}
                alt="logo"
              />
            </a>
          </div>
          <div className=" btn">
            <Link to={"la-coqueta"}>
              <StaticImage
                src="../assets/images/BOTON-CAFE.png"
                className=""
                placeholder="none"
                style={{ maxWidth: "130px" }}
                width={130}
                alt="logo"
              />
            </Link>
          </div>
          <div className="btn">
            <Link to={"minibosques"}>
              <StaticImage
                src="../assets/images/BOTON-MINI_BOSQUES.png"
                className=""
                placeholder="none"
                style={{ maxWidth: "240px" }}
                width={240}
                alt="logo"
              />
            </Link>
          </div>

          <div className="btn">
            <a href="https://wa.me/c/573216168239">
              <StaticImage
                src="../assets/images/WHSPP.png"
                className=""
                placeholder="none"
                style={{ maxWidth: "240px" }}
                width={240}
                alt="logo"
              />
            </a>
          </div>
        </div>
      </HeaderStyles>
    </div>
  );
}

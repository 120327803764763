import React from 'react';
import styled from  'styled-components';

const DivStyles = styled.div`
    position: relative;
    border: none;
    iframe {
        border: none;
        height: 100vh;
        width: 100vw;
    }
`

const Frame = () => {
    return <DivStyles>
        <iframe src="https://treinta.shop/minibosqueswaspen" />
    </DivStyles>;
}

export default React.memo(Frame)